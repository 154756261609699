import React from 'react';
import League from '@leagueplatform/core';
import { Route, Switch, Redirect } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  CSAuthSessionContainer,
  CSAuthSignIn,
  CSAuthSignOut,
  BrowserRouter,
} from '@highmark-web/highmark-common';
import { CustomerSupportRoutes } from '@leagueplatform/cse-customer-support';
import { config } from './init-config';

export function App() {
  return (
    <BrowserRouter>
      <CompatRouter>
        <League.LeagueProvider config={config}>
          <Switch>
            <Route path="/start-login" component={CSAuthSignIn} />
            <Route path="/sign-out" component={CSAuthSignOut} />
            <CSAuthSessionContainer>
              <Switch>
                <Route
                  path="/customer-support"
                  component={CustomerSupportRoutes}
                />
                <Route>
                  <Redirect to="/customer-support" />
                </Route>
              </Switch>
            </CSAuthSessionContainer>
          </Switch>

          <ReactQueryDevtools />
        </League.LeagueProvider>
      </CompatRouter>
    </BrowserRouter>
  );
}
